/* You can add global styles to this file, and also import other style files */


@use '@angular/material' as mat;
@import "~firebaseui/dist/firebaseui.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  .bg-customDarkBlue {
    @apply bg-[#050C9C];
  }
  .bg-customBlue {
    @apply bg-[#3572EF];
  }
  .bg-customLightBlue {
    @apply bg-[#3ABEF9];
  }
  .bg-customVeryLightBlue {
    @apply bg-[#A7E6FF];
  }
  .text-customDarkBlue {
    @apply text-[#050C9C];
  }
  .text-customBlue {
    @apply text-[#3572EF];
  }
  .text-customLightBlue {
    @apply text-[#3ABEF9];
  }
  .text-customVeryLightBlue {
    @apply text-[#A7E6FF];
  }
  .border-customBlue {
    @apply border-[#3572EF];
  }
  .border-customLightBlue {
    @apply border-[#3ABEF9];
  }
  .border-customVeryLightBlue {
    @apply border-[#A7E6FF];
  }
}

.glass-effect {
  position: relative;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.pulse-button {
  animation: pulse 2s infinite;
  background: linear-gradient(90deg, #007bff, #0056b3);
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px; /* Full rounded corners */
  box-shadow: 0 4px 14px 0 rgba(0, 118, 255, 0.39);
  transition: background 0.3s, transform 0.3s;
}

.pulse-button:hover {
  background: linear-gradient(90deg, #0056b3, #007bff);
  transform: scale(1.05);
}
// Include non-theme styles for core.
@include mat.core();

$mat-primary: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #1a66d2,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-accent: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #f6830f,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);

// Define a theme.
$primary: mat.define-palette($mat-primary);
$accent:  mat.define-palette($mat-accent, A200, A100, A400);

$theme: mat.define-light-theme($primary, $accent);

// Include all theme styles for the components.
@include mat.all-component-themes($theme);
